import React from 'react';
// eslint-disable-next-line
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import ChatPage from './pages/ChatPage';
import reportWebVitals from './reportWebVitals';
import printEnv from './printEnv';
import ResourceViewer from "./components/ResourceViewer";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/", element: <ChatPage />,
    },
    {
        path: "/resource/:entryId", element: <ResourceViewer/>
    }
]);


root.render(
  // <ChatPage />
  // <React.StrictMode>
  //   <Router>
  //       <Routes>
  //           <Route path="/" element={<ChatPage />} />
  //           <Route path="/resource" element={} />
  //       </Routes>
  //   </Router>
  // </React.StrictMode>
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

reportWebVitals();
printEnv();