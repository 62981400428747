import axios from 'axios';
import ScreenContentfulModel from '../models/ContentfulModel';
import DefaultResponseHandler from './ResponseHandler';
const queryString = require('query-string');
const contentful = require('contentful');
const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_CONTENTFUL_DOMAIN,
    // baseURL: 'https://cdn.contentful.com',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer z6AIAvpQUt5adeE67RmFPXL1s8bYMzzRR5V2JB4Aw4Y`
    }
});


let contentfulClient = null;

// eslint-disable-next-line
const cdn = () => {
    if ( contentfulClient === null ) {
        contentfulClient = contentful.createClient( {
            // space: 'n64bibf2ruu3',
            // accessToken: 'RTnCeMteF-PWAqlEWpF5IbHZE16N_rO_cR60MjPdqls',
            space: 'v98oetbd7ayp',
            accessToken: 'z6AIAvpQUt5adeE67RmFPXL1s8bYMzzRR5V2JB4Aw4Y',
        } );
    }
    return contentfulClient;
}


/**
 * Contentful
 */
const apiController = `/spaces/v98oetbd7ayp/entries`;

export const CONTENT_TYPES = {
    ActionStatuses: 'actionStatus',
    CheckInQuestions: 'checkInQuestions',
    SupportServices: 'supportServices',
    SetFocus: 'setFocus',
    Resources: 'article',
    Onboarding: 'onboardingCopy',
    FocusArea: 'focusAreas',
    Priorities: 'priorities',
    Actions: 'actions',
    About: 'aboutCopy',
    RateActions: 'rateActionQuickResponse',
    Review: 'reviewProgress',
    InspirationCarousel: 'inspirationCarousel',
    ProgressUpdate: 'progressUpdate',
    Factor: 'factor',
    subFactor: 'subFactor',
    WelcomeMessage: 'modProfUserInfo',
    HoverCopy: 'hoverCopy',
    Content: 'modularProfilerContent',
    ChartDescription: 'modularProfilerChartDescriptions',
    ChatbotBeta: 'chatbotBeta'
};

const API_CONTENT = () => `${apiController}`;
/**
 * Contentful
 */

let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */
const executeRequest = (promise) => {
    return new Promise((resolve, reject) => {
        const handler =
            responseHandler && typeof responseHandler === 'function'
                ? responseHandler
                : defaultResponseHandler;
        promise
            .then((response) => {
                handler.handleSuccess(response, resolve, reject);
                responseHandler = undefined;
            })
            .catch((error) => {
                handler.handleError(error, reject);
                responseHandler = undefined;
            });
    });
};

const doGetRequest = (url, params, config) => {
    if (params) {
        url += `?${queryString.stringify(params)}&limit=200`;
    }
    return executeRequest(apiInstance.get(url, config));
};

const doGetRequestWithTag = (url, params, config) => {
    const organisationTag = window.localStorage.getItem('organisationTag') ?? 'default';

    if (params) {
        url += `?${queryString.stringify(params)}&limit=200&metadata.tags.sys.id[in]=default,${organisationTag}`;
    }
    return executeRequest(apiInstance.get(url, config));
};

async function getChatbotIntroText(id) {
    let result = null

    await cdn().getEntry(id)
        .then((entry) => {
            // console.log(entry.sys);
            // console.log(entry.fields.text);
            // result = entry.fields.text.toString();
            result = entry.fields.mdText.toString();
            return result;
        })
        .catch((error) => {
            console.error(error)
            return `Introducing the Future of early Burnout detection and Psychosocial Risk assessments: Welcome to the Benny Button early-release, beta-version Chatbot! ✨`
        })

    if ( result?.isEmpty ) {
        result = `Introducing the Future of early Burnout detection and Psychosocial Risk assessments: Welcome to the Benny Button early-release, beta-version Chatbot! ✨\n\n
        Embark on a revolutionary journey towards tranquility with our cutting-edge Benny Button Burnout Chatbot – your personal companion in the quest for stress-free living. We're thrilled to announce the beta release of this innovative chatbot designed to assist you in navigating life's challenges and preventing burnout.
        Harnessing the power of artificial intelligence, Benny Button is not just a chatbot; it's your dedicated ally in promoting mental well-being. Engage in meaningful conversations, receive personalized tips, and explore effective strategies to combat stress. Whether you're facing work pressures, personal dilemmas, or simply seeking a moment of relaxation, Benny Button Burnout Chatbot is here for you.
        Be among the first to experience this groundbreaking technology! We're eagerly inviting beta users to join us on this exciting journey of discovery and self-care. Together, let's redefine the way we handle stress and embrace a brighter, more balanced future.
        Join us in testing Benny Button Burnout Chatbot and be part of a community committed to well-being. Your feedback will shape the future of this transformative tool. Ready to embark on a stress-free adventure? Sign up now and let the beta testing begin!`
    }

    return result
}


async function getWellbeingResourceArticle( contentfulEntryId ) {
    let response = null;
    let error = null;
    await cdn().getEntry( contentfulEntryId.trim() )
        .then((entry) => {
            // console.log( "received result from Contentful API: " + entry )
            response = entry
        })
        .catch((err) => {
            console.error(err.message)
            error = err
            return error``
        })
    return response
}





// const getContentInPage = (contentType, config) => {
//     return doGetRequest(
//         API_CONTENT(),
//         config
//     ).then((response) => {
//         response.data = ScreenContentfulModel.parseFromJson(response.data);
//         return response;
//     });
// };


/**
 * Utilities
 */

const getContentInPage = (contentType, config) => {
    return doGetRequest(
        API_CONTENT(),
        { content_type: contentType },
        config
    ).then((response) => {
        response.data = ScreenContentfulModel.parseFromJson(response.data);
        return response;
    });
};

export const getContent = (contentType, config) => {
    return doGetRequest(API_CONTENT(), { content_type: contentType }, config);
};

export const getContentWithTag = (contentType, config) => {
    return doGetRequestWithTag(API_CONTENT(), { content_type: contentType }, config);
};

const exported = {
    apiInstance,
    CONTENT_TYPES,
    getContentInPage,
    getContent,
    getContentWithTag,
    getChatbotIntroText,
    getWellbeingResourceArticle,
};

export default exported;
