export class ContentfulAsset {
    id;
    assetUrl;

    constructor(id, assetUrl) {
        this.id = id;
        this.assetUrl = assetUrl;
    }
}
export class ContentfulModel {
    contentId;
    name;
    text;
    mdText;
    richText;

    static parseFromJson = (data) => {
        const { contentId, name, text, mdText, richText } = data;
        const contenful = new ContentfulModel();
        contenful.contentId = contentId
        contenful.name = name;
        contenful.text = text;
        contenful.mdText = mdText;
        contenful.richText = richText;
        return contenful;
    }
}

export default class ScreenContentfulModel {
    items;

    constructor() {
        this.items = new Map();
    }

    static parseFromJson = (data) => {
        const obj = new ScreenContentfulModel();
        const { items, includes } = data;

        if (items && Array.isArray(items)) {
            const { Asset } = includes || {};
            const AssetById = {};

            if (Asset && Array.isArray(Asset)) {
                Asset.forEach(item => {
                    const { sys, fields } = item;
                    const url = `https:${fields?.file.url ?? ''}`;
                    const id = sys?.id ?? '';
                    AssetById[id] = new ContentfulAsset(id, url);
                });
            }

            items.forEach(item => {
                const { fields, sys } = item;
                if (fields) {
                    const assetId = fields?.thumbnailImage?.sys.id ?? '';
                    const contenful = ContentfulModel.parseFromJson(fields);
                    contenful.id = sys?.id ?? '';
                    contenful.image = AssetById[assetId]?.assetUrl;
                    obj.items.set(fields.name ?? fields.mpKey ?? fields.title, contenful);
                }
            });
        }
        return obj;
    }
}