import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import "../styles/styles.css"
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";

const ResourceViewer = () => {
    const { entryId } = useParams();
    const [entry, setEntry] = useState(null);
    // const [hasArticleImage, setHasArticleImage] = useState(false);
    // const [articleImage, setArticleImage] = useState([])
    const [hasMedia, setHasMedia] = useState(false);
    const [media, setMedia] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://cdn.contentful.com/spaces/v98oetbd7ayp/entries/${entryId}?access_token=z6AIAvpQUt5adeE67RmFPXL1s8bYMzzRR5V2JB4Aw4Y`
                );

                setEntry(response.data.fields);

                // if ( response.data.fields["thumbnail"]?.fields["file"]["url"]?.trim()?.length > 0 ) {
                //     setArticleImage(["https:" + response.data.fields["thumbnail"]?.fields["file"]["url"]]);
                //     setHasArticleImage(true);
                // } else {
                //     setArticleImage([]);
                //     setHasArticleImage(false);
                // }

                if (response.data.fields['embedMedia']?.trim()?.length > 0 ) {
                    setMedia(response.data.fields['embedMedia']);
                    setHasMedia(true);
                } else {
                    setHasMedia(false);
                    setMedia('');
                }


            } catch (error) {
                console.error('Error fetching content from Contentful:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [entryId]);

    return (
        <div>
            <p className="subtitle-lato"></p>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="resource-container">

                    {/*{hasArticleImage && (articleImage.length > 0) && <img src={articleImage[0]} alt="article-image-resource" width={300}/>}*/}

                    <p className="subtitle">{entry.title}</p>
                    <Markdown remarkPlugins={[remarkGfm]}>{entry.hashtag}</Markdown>

                    {hasMedia &&
                        <div className="media-viewer">
                            <iframe
                                width="560"
                                height="315"
                                src={media}
                                title={entry.title}
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                            />
                        </div>
                    }

                    <Markdown remarkPlugins={[remarkGfm]}>{entry.description}</Markdown>
                    {/* Add more fields as needed */}
                </div>
            )}
        </div>
    );
};

export default ResourceViewer;